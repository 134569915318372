import formModel from './formModel';
const {
  formField: {
    firstName,
    lastName,
    jobTitle,
    email,
    confirmEmail,
    orgName,
    charitysize,
    q1,
    q2,
    q3,
    q4,
    q5,
    q6,
    q7,
    q8,
    q9,
    q10,
    q11,
    q12,
    q13,
    q14,
    q15,
    q16,
    q17,
    q18,
    q19,
    q20,
    q21,
    q22,
    q23,
    q24,
    q25,
    q26,
    q27,
    q28,
    q29,
    q30,
    q31
  }
} = formModel;

const DEVTEXT = 'development1';

export default {
  [firstName.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'B' : '',
  [lastName.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'A' : '',
  [jobTitle.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'D' : '',
  [email.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'U@u.com' : '',
  [confirmEmail.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'U@u.com' : '',
  [orgName.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'O' : '',
  [charitysize.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? ['Kent', 'Hampshire'] : [],

  /* [q1.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Lorem ipsum' : '',
  [q2.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Lorem ipsum' : '',
  [q3.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? ['Operations that are fit for purpose', 'People and Culture'] : [],
  [q4.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Lorem ipsum' : '',
  [q5.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Lorem ipsum' : '',
  [q6.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? '505050' : '',
  [q7.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? '22222' : '',

  [q8.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? '16. Lorem ipsum' : '',
  [q9.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? '17. Lorem ipsum' : '',
  [q10.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? ['Effective leadership'] : [],
  [q11.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? '19. Lorem ipsum' : '', */
}; 