import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { checkPropTypes } from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { Grid, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function SimplePopover(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div style={{...props.style, position: 'absolute', right:props.positionRight || 8, top: props.positionTop || -12, backgroundColor:'#fff', zIndex:10 }}>
      <IconButton aria-describedby={id} variant="contained" onClick={handleClick} style={{width:24,height:24}}>
        <InfoIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}

      >
        <Grid container style={{maxWidth:600, padding:8, color:'#0000008a'}}>
          <Grid item xs={12} sm={12}>
          <Box p={2}><div dangerouslySetInnerHTML={{ __html: props.content }}></div> </Box>
          </Grid>
        </Grid>
      </Popover>
    </div>
  );
}
