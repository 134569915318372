import React, { useEffect } from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { InputRichTextField } from '../../fields';
import useStyles from '../styles';
import { useFormikContext } from 'formik';
import { FormMode } from '../models/FormMode';
import EditIcon from '@material-ui/icons/Edit';
import { ReportFields } from '../../common/DataTypes';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function Section4(props) {
    const classes = useStyles();
    const { values: formValues } = useFormikContext();
    const title = <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>{props.steps[props.stepId]}</Typography>;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const {
        editable,
        formField: {
            q21,
            q22,
            q23,
            q24,

        },
        getField,
        mode
    } = props;

    const fieldQ21 = props.init && getField(ReportFields.Question21);
    const fieldQ22 = props.init && getField(ReportFields.Question22);
    const fieldQ23 = props.init && getField(ReportFields.Question23);
    const fieldQ24 = props.init && getField(ReportFields.Question24);

    const q21Desc = props.init && props.init.formTexts.FirstName;
    const q22Desc = props.init && props.init.formTexts.FirstName;
    const q23Desc = props.init && props.init.formTexts.FirstName;
    const q24Desc = props.init && props.init.formTexts.FirstName;


    const onEdit = (step) => {
        if (props.onEdit) {
            props.onEdit(step);
        }
    }

    const edit = mode === FormMode.Review && editable && <IconButton aria-label="edit" onClick={() => onEdit(props.stepId)} className={classes.buttonEdit}><EditIcon /></IconButton>;

    return (
        <div className={classes.section1}>
            {(mode === FormMode.Review) &&
                <Grid container>
                    <Grid item xs={12}>
                        <List>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    {title}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12}>
                                                <ListItem style={{ paddingLeft: 0 }} button><ListItemText primary={`${fieldQ21.description}`} secondary={''} /></ListItem>
                                                <div className={classes.reviewRichtext} dangerouslySetInnerHTML={{ __html: formValues.q21 }}></div>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12}>
                                                <ListItem style={{ paddingLeft: 0 }} button><ListItemText primary={`${fieldQ22.description}`} secondary={''} /></ListItem>
                                                <div className={classes.reviewRichtext} dangerouslySetInnerHTML={{ __html: formValues.q22 }}></div>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12}>
                                                <ListItem style={{ paddingLeft: 0 }} button><ListItemText primary={`${fieldQ23.description}`} secondary={''} /></ListItem>
                                                {formValues.q23 && formValues.q23.indexOf('<p><br></p>') === -1 && <div className={classes.reviewRichtext} dangerouslySetInnerHTML={{ __html: formValues.q23 }}></div>}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12}>
                                                <ListItem style={{ paddingLeft: 0 }} button><ListItemText primary={`${fieldQ24.description}`} secondary={''} /></ListItem>
                                                {formValues.q24 && formValues.q24.indexOf('<p><br></p>') === -1 && <div className={classes.reviewRichtext} dangerouslySetInnerHTML={{ __html: formValues.q24 }}></div>}
                                                {edit}
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </List>
                    </Grid>
                </Grid>
            }

            {(mode === FormMode.Edit) &&
                <>
                    <Grid item xs={12}>
                        {title}
                        We would love to hear what key organisational milestones you have achieved to better understand your journey over the last year.
                    </Grid>
                    <Grid container spacing={3} style={{ marginTop: 24 }} >
                        <Grid item xs={12} sm={12}>
                            <InputRichTextField
                                name={q21.name}
                                label={`${fieldQ21.description} *`}
                                fullWidth
                                color="secondary" variant="outlined"
                                description={q21Desc}
                            /></Grid>
                        <Grid item xs={12} sm={12}>
                            <InputRichTextField
                                name={q22.name}
                                label={`${fieldQ22.description}`}
                                fullWidth
                                color="secondary" variant="outlined"
                                description={q22Desc}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <InputRichTextField
                                name={q23.name}
                                label={`${fieldQ23.description}`}
                                fullWidth
                                color="secondary" variant="outlined"
                                description={q23Desc}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <InputRichTextField
                                name={q24.name}
                                label={`${fieldQ24.description}`}
                                fullWidth
                                color="secondary" variant="outlined"
                                description={q24Desc}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                            <div style={{ float: 'right', fontWeight: 200, fontSize: '.9em', color: '#0000008a' }}><span>*</span> indicates required</div>
                        </Grid>
                    </Grid>
                </>
            }
        </div>
    )
}