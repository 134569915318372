export default {
  formId: 'securityCheckForm',
  formField: {
    withCode: {
      name: 'withCode',
      label: 'I have a code to continue my application'
    },
    code: {
      name: 'code',
      label: 'Enter Code *',
      requiredErrorMsg: 'Code is required',
      invalidErrorMsg: 'Invalid code, please enter valid code and try again!'
    }
  }
};
