import React from 'react';
import {
    Button,
    Typography,
    CircularProgress,
    Grid
} from '@material-ui/core';
import { InputField } from '../fields';
import useStyles from './styles';
import { Formik, Form } from 'formik';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import securityCheckValidationSchema from './models/securityCheckValidationSchema';
import securityCheckFormModel from './models/securityCheckFormModel';
import securityCheckFormInitialValues from './models/securityCheckFormInitialValues';
import formInitialValues from './models/formInitialValues';
import { ReportFields } from '../common/DataTypes';


const { formId, formField } = securityCheckFormModel;

export default function SecurityCheck(props) {
    const classes = useStyles();
    const [errorCode, setErrorCode] = React.useState('');
    const currentValidationSchema = securityCheckValidationSchema[0];
    const currentCode = window.location.search.replace('?', '');
    const [withCode] = React.useState(currentCode ? true : true);
    securityCheckFormInitialValues.code = currentCode || '';
    const title = props.init && props.init.formTexts.Title;

    async function _submitForm(values, actions) {
        console.log('_submitForm');
        if (withCode && values.code) {
            actions.setSubmitting(true);
            getStage(props.config, props.currentCycle, values.code).then(stage => {
                if (stage) {
                    const item = stage.Item;
                    //Section 1
                    formInitialValues.firstName = item[ReportFields.FirstName];
                    formInitialValues.lastName = item[ReportFields.LastName];
                    formInitialValues.email = item[ReportFields.Email];
                    formInitialValues.confirmEmail = item[ReportFields.Email];
                    formInitialValues.jobTitle = item[ReportFields.JobTitle];
                    formInitialValues.orgName = item[ReportFields.OrgName];
                    formInitialValues.charitysize = item[ReportFields.CharitySize];
                    //Section 2
                    formInitialValues.q6a = item[ReportFields.Question6a];
                    formInitialValues.q6b = item[ReportFields.Question6b];
                    formInitialValues.q6c = item[ReportFields.Question6c];
                    formInitialValues.q6d = item[ReportFields.Question6d];
                    formInitialValues.q6e = item[ReportFields.Question6e];
                    formInitialValues.q6f = item[ReportFields.Question6f];
                    formInitialValues.q6g = item[ReportFields.Question6g];
                    formInitialValues.q6h = item[ReportFields.Question6h];
                    formInitialValues.q6i = item[ReportFields.Question6i];
                    // formInitialValues.q6j = item[ReportFields.Question6j];
                    // formInitialValues.q6jtxt = item[ReportFields.Question6jtxt];
                    formInitialValues.q6 = item[ReportFields.Question6];
                    formInitialValues.q7 = item[ReportFields.Question7];
                    formInitialValues.q8 = item[ReportFields.Question8];
                    formInitialValues.q8a = item[ReportFields.Question8a];
                    formInitialValues.q9 = item[ReportFields.Question9];
                    //  formInitialValues.q9a = item[ReportFields.Question9a];
                    formInitialValues.q10 = item[ReportFields.Question10];
                    // formInitialValues.q10a = item[ReportFields.Question10a];
                    formInitialValues.q11 = item[ReportFields.Question11];
                    // formInitialValues.q11a = item[ReportFields.Question11a];
                    formInitialValues.q12 = item[ReportFields.Question12];
                    //  formInitialValues.q12a = item[ReportFields.Question12a];
                    formInitialValues.q13 = item[ReportFields.Question13];
                    // formInitialValues.q13a = item[ReportFields.Question13a];
                    //Section 3
                    formInitialValues.q14a = item[ReportFields.Question14a];
                    formInitialValues.q14b = item[ReportFields.Question14b];
                    formInitialValues.q14c = item[ReportFields.Question14c];
                    formInitialValues.q14d = item[ReportFields.Question14d];
                    formInitialValues.q14e = item[ReportFields.Question14e];
                    formInitialValues.q28 = item[ReportFields.Question28];
                    // formInitialValues.q14etxt = item[ReportFields.Question14etxt];
                    formInitialValues.q15 = item[ReportFields.Question15];
                    // formInitialValues.q15a = item[ReportFields.Question15a];
                    formInitialValues.q16 = item[ReportFields.Question16];
                    //  formInitialValues.q16a = item[ReportFields.Question16a];
                    formInitialValues.q17 = item[ReportFields.Question17];
                    //  formInitialValues.q17a = item[ReportFields.Question17a];
                    formInitialValues.q18 = item[ReportFields.Question18];
                    //   formInitialValues.q18a = item[ReportFields.Question18a];
                    formInitialValues.q19 = item[ReportFields.Question19];
                    //  formInitialValues.q19a = item[ReportFields.Question19a];
                    formInitialValues.q20 = item[ReportFields.Question20];

                    //Section 4
                    formInitialValues.q21 = item[ReportFields.Question21];
                    formInitialValues.q22 = item[ReportFields.Question22];
                    formInitialValues.q23 = item[ReportFields.Question23];
                    formInitialValues.q24 = item[ReportFields.Question24];

                    stage.formInitialValues = formInitialValues;
                    stage.showIntro = false;
                    actions.setSubmitting(false);
                    props.handleSecurityCheck(stage);
                    actions.setSubmitting(false);
                } else {
                    setErrorCode(formField.code.invalidErrorMsg);
                    actions.setSubmitting(false);
                }
            });
        } else {
            actions.setSubmitting(false);
            setErrorCode(formField.code.requiredErrorMsg)
            return false;
        }
    }

    function _handleSubmit(values, actions) {
        _submitForm(values, actions);
    }

    const getStage = (config, cycle, code) => {
        console.log("Fetching:", config.ApiUrl);

        return new Promise((resolve, reject) => {
            const postData = {
                siteId: cycle.initData.siteId,
                listId: cycle.initData.listId,
                accessCode: code,
                docLibId: cycle.initData.docLibId
            };

            let apiUrl = `${config.ApiUrl}/stage`;

            return fetch(apiUrl,
                {
                    body: JSON.stringify(postData),
                    method: "POST",
                    cache: "no-cache",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                        "Authorization": `Bearer ${config.ApiToken}`
                    }
                })
                .then(response => response.json())
                .then(json => {
                    resolve(json.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    const securityCheckIntro = props.init && props.init.formTexts.SecurityCheckIntro;
    return (
        <React.Fragment>
            <div className={classes.eligibilityCheck}>
                <Typography component="h1" variant="h4" align="center" className={classes.title} color="secondary">
                    <div dangerouslySetInnerHTML={{ __html: title }}></div>
                </Typography>
                <Formik
                    initialValues={securityCheckFormInitialValues}
                    validationSchema={currentValidationSchema}
                    onSubmit={_handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form id={formId}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <div dangerouslySetInnerHTML={{ __html: securityCheckIntro }}></div>
                                </Grid>

                                {withCode && <Grid item xs={12} style={{ paddingBottom: 12 }}>
                                    <InputField name={formField.code.name} label={`${formField.code.label}`} fullWidth variant="outlined" color="secondary" />
                                    {errorCode && <span style={{ color: "#ce0d28" }}>{errorCode}</span>}
                                </Grid>}
                                <Grid item xs={12}>
                                    <div className={classes.wrapper}>
                                        <div className={classes.buttons}>
                                            <Button
                                                disabled={isSubmitting}
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                                className={classes.buttonNext}
                                                endIcon={<ArrowRightIcon />}
                                            >{withCode ? 'Continue' : 'Start'}</Button>
                                            {isSubmitting && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>
        </React.Fragment>
    )
}