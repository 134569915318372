import React, { useState, useRef } from 'react';
import {
    Typography,
    Stepper,
    Step,
    StepLabel,
    Button,
    CircularProgress
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import SendIcon from '@material-ui/icons/Send';
import SaveIcon from '@material-ui/icons/Save';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import validationSchema from './models/validationSchema';

import formModel from './models/formModel';
import { Section1, Section2, Section3, Section4, Review, Success } from './sections';
import useStyles from './styles';
import ReCAPTCHA from "react-google-recaptcha";
import { FormMode } from './models/FormMode';

const STEPS = {
    Section1: 0,
    Section2: 1,
    Section3: 2,
    Section4: 3,
    Review: 4,
    Success: 5,
    SaveforLaterSuccess: 6
}
const steps = ['Personal and Organisation Details', 'Use and Impact of Funding', 'Volunteering and Pro-bono Support', 'Organisational Milestones', 'Review and Submit'];
const { formId, formField } = formModel;

function renderStepContent(step, props) {
    switch (step) {
        case STEPS.Section1:
            return <Section1 formField={formField} {...props} mode={FormMode.Edit} />;
        case STEPS.Section2:
            return <Section2 formField={formField} {...props} mode={FormMode.Edit} />;
        case STEPS.Section3:
            return <Section3 formField={formField} {...props} mode={FormMode.Edit} />;
        case STEPS.Section4:
            return <Section4 formField={formField} {...props} mode={FormMode.Edit} />;
        case STEPS.Review:
            return <Review formField={formField} {...props} mode={FormMode.Edit} />;
        default:
            return <div>Not Found</div>;
    }
}

export default function MainForm(props) {
    const { stage } = props;
    const [activeStep, setActiveStep] = useState(0);
    const currentValidationSchema = validationSchema[activeStep];
    const isLastStep = activeStep === steps.length - 1;
    const recaptchaRef = useRef();
    const classes = useStyles();
    const title = props.init && props.init.formTexts.Title;
    const [attachments, setAttachments] = useState([]);


    const [serverErrors, setServerErrors] = useState([]);
    const [disableNext, setDisableNext] = useState(false);
    const [saveforLater, setSaveforLater] = useState(false);

    const getChoices = (name) => {
        let { fields } = props.init;
        let field = fields && fields.value?.filter((f) => f['name'] === name)[0];
        let choices = field && field['choice']['choices'] ? field['choice']['choices'] : [];
        return choices;
    }

    const getField = (name) => {
        let { fields } = props.init;
        let field = fields && fields.value?.filter((f) => f['name'] === name)[0];
        return field;
    }

    const tryParse = (value) => {
        if (value) {
            return parseFloat(value);
        } else {
            return 0;
        }
    }

    const validateForm = (validate) => {
        setDisableNext(validate);
    }


    async function saveForLaterForm(values, actions) {
        let token = '';
        if (props.init.humanEnabled) {
            if (recaptchaRef.current) {
                token = await recaptchaRef.current.executeAsync();
                recaptchaRef.current.reset();
            }
        }
        setServerErrors([]);
        //let currentCycle = props.currentCycle;

        const postData = {
            ...values,
            q6: tryParse(values.q6a) + tryParse(values.q6b) + tryParse(values.q6c) + tryParse(values.q6d) + tryParse(values.q6e) + tryParse(values.q6f) + tryParse(values.q6g) + tryParse(values.q6h) + tryParse(values.q6i) + tryParse(values.q6j),
            q14: tryParse(values.q14a) + tryParse(values.q14b) + tryParse(values.q14c) + tryParse(values.q14d) + tryParse(values.q14e),
            cycle: props.currentCycle,
            initData: props.init,
            token,
            itemId: stage.ReportId,
            isSaveforlater: true,
            saveforlaterSendEmail: true,
            humanEnabled: props.init.humanEnabled
        };
        let apiUrl = `${props.config.ApiUrl}/updateReport`;// process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? process.env.REACT_APP_API_URL:'/api'; 
        const response = await fetch(apiUrl,
            {
                body: JSON.stringify(postData),
                method: "POST",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "Authorization": `Bearer ${props.config.ApiToken}`
                }
            })
            .catch(error => {
                setServerErrors([{ message: error }]);
                actions.setSubmitting(false);
            });
        const result = await response.json();
        console.log('result', result);
        if (result) {
            if (result.data) {
                //uploadFiles(actions, attachments, result.data.BGApplication);
                const activeStep = saveforLater ? STEPS.SaveforLaterSuccess : STEPS.Success;
                setActiveStep(activeStep);
                setServerErrors([]);
                actions.setSubmitting(false);
            }
        }
        else if (result.errors) {
            setServerErrors(result.errors);
            actions.setSubmitting(false);
        } else if (result.error) {
            setServerErrors([{ message: result.error }]);
            actions.setSubmitting(false);
        }
    }

    async function submitForm(values, actions) {
        let token = '';
        if (props.init.humanEnabled) {
            if (recaptchaRef.current) {
                token = await recaptchaRef.current.executeAsync();
                recaptchaRef.current.reset();
            }
        }
        setServerErrors([]);

        const postData = {
            ...values,
            q6: tryParse(values.q6a) + tryParse(values.q6b) + tryParse(values.q6c) + tryParse(values.q6d) + tryParse(values.q6e) + tryParse(values.q6f) + tryParse(values.q6g) + tryParse(values.q6h) + tryParse(values.q6i) + tryParse(values.q6j),
            q14: tryParse(values.q14a) + tryParse(values.q14b) + tryParse(values.q14c) + tryParse(values.q14d) + tryParse(values.q14e),
            cycle: props.currentCycle,
            initData: props.init,
            token,
            itemId: stage.ReportId,
            isSaveforlater: false,
            stage: "Submitted",
            humanEnabled: props.init.humanEnabled
        };
        let apiUrl = `${props.config.ApiUrl}/updateReport`;// process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? process.env.REACT_APP_API_URL:'/api'; 
        const response = await fetch(apiUrl,
            {
                body: JSON.stringify(postData),
                method: "POST",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "Authorization": `Bearer ${props.config.ApiToken}`
                }
            })
            .catch(error => {
                setServerErrors([{ message: error }]);
                actions.setSubmitting(false);
            });
        const result = await response.json();
        console.log('result', result);
        if (result) {
            if (result.data) {
                //uploadFiles(actions, attachments, result.data.BGApplication);
                const activeStep = saveforLater ? STEPS.SaveforLaterSuccess : STEPS.Success;
                setActiveStep(activeStep);
                setServerErrors([]);
                actions.setSubmitting(false);
            }
        }
        else if (result.errors) {
            setServerErrors(result.errors);
            actions.setSubmitting(false);
        } else if (result.error) {
            setServerErrors([{ message: result.error }]);
            actions.setSubmitting(false);
        }
    }

    function handleSubmit(values, actions) {
        if (saveforLater) {
            saveForLaterForm(values, actions, props.init, props.currentCycle, stage);
            return;
        } else if (isLastStep) {
            submitForm(values, actions);
        } else {
            let nextStep = activeStep + 1;
            setActiveStep(nextStep);
            actions.setTouched({});
            actions.setSubmitting(false);
            //if (nextStep ===STEPS.SupportDocs && !docsExists) {
            //setDisableNext(attachments.length ===0);
            //}
        }
    }

    function handleBack() {
        let prevStep = activeStep - 1;
        setActiveStep(prevStep);
    }

    const onEdit = (step) => {
        setActiveStep(step);
    }

    const handleAttachments = (array) => {
        array.forEach((item, index) => {
            attachments.push(item);
        });
        setAttachments(attachments);
    }

    const onFilesRemoved = (array) => {
        setAttachments(array);
    }

    const formProps = { ...props, steps, stepId: activeStep, onEdit, getChoices, getField, handleAttachments, attachments, onFilesRemoved, tryParse, validateForm };


    return (
        <>
            <Typography component="h1" variant="h4" align="center" className={classes.title} color="secondary">
                <div dangerouslySetInnerHTML={{ __html: title }}></div>
            </Typography>
            {(activeStep === STEPS.SaveforLaterSuccess || activeStep === STEPS.Success) ? (
                <Success {...formProps} textKey={activeStep === STEPS.SaveforLaterSuccess ? "SaveforLaterSuccessText" : "Success"} />
            ) : (
                <React.Fragment>
                    {
                        activeStep < STEPS.Review && <Stepper activeStep={activeStep} className={classes.stepper}>
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    {/* {stage && (index < STEPS.Section6) ? <StepLabel><Link component="button" variant="body2" color="default" onClick={() => { onEdit(index) }}>{label}</Link></StepLabel> : <StepLabel>{label}</StepLabel>} */}
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    }
                    <Formik
                        initialValues={stage.formInitialValues}
                        validationSchema={!saveforLater && currentValidationSchema}
                        onSubmit={handleSubmit}
                        style={{ width: '100%' }}
                    >
                        {({ isSubmitting }) => (
                            <Form id={formId}>
                                {renderStepContent(activeStep, formProps)}
                                <div className={classes.buttons} >
                                    <div className={classes.wrapper}>
                                        {activeStep !== STEPS.Section1 && (
                                            <Button onClick={handleBack} startIcon={<ArrowLeftIcon />} className={classes.buttonBack} variant="contained" color="default">
                                                Back
                                            </Button>
                                        )}
                                        {activeStep > 0 && <Button
                                            variant="contained"
                                            color="secondary"
                                            type="submit"
                                            onClick={() => { setSaveforLater(true); }}
                                            className={classes.buttonSaveforLater}
                                            endIcon={<SaveIcon />}
                                        >
                                            Save for later
                                        </Button>}
                                        <Button
                                            disabled={disableNext}
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            className={classes.buttonNext}
                                            endIcon={isLastStep ? <SendIcon /> : <ArrowRightIcon />}
                                        >
                                            {isLastStep ? 'Submit' : 'Next'}
                                        </Button>
                                        {isSubmitting && (
                                            <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="reCaptcha">
                                    {(activeStep === STEPS.Review || saveforLater) && props.init.humanEnabled && <ReCAPTCHA
                                        sitekey={props.config.RecaptchaKey}
                                        ref={recaptchaRef}
                                        size="invisible"
                                    />
                                    }
                                </div>
                            </Form>
                        )}
                    </Formik>
                </React.Fragment>
            )
            }
        </>
    )
}