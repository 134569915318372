export const TEXT_NONE_OF_THE_ABOVE = 'None of the above';
export const TEXT_PREFER_NOT_TO_SAY = 'Prefer not to say';
export const TEXT_TOTAL_EXCEED = 'Total must be 100%';

export const SelectType = {
   RADIO: 1,
   CHECK: 2,
   NONE: 3
}


export const ReportFields = {
   ID: "ID",
   Ref: "CRReportRef",
   FirstName: "FirstName",
   LastName: "BGLastName",
   JobTitle: "JobTitle",
   Email: "BGEmail",
   OrgName: "BGOrganisationName",
   CharitySize: "BGCharitySize",
   Partnership: "BGPartnership",
   PartnershipRef: "BGPartnershipRef",
   ReportStatus: "CRReportStatus",
   Question6:'CRQuestion6',
   Question6a:'CRQuestion6a',
   Question6b:'CRQuestion6b',
   Question6c:'CRQuestion6c',
   Question6d:'CRQuestion6d',
   Question6e:'CRQuestion6e',
   Question6f:'CRQuestion6f',
   Question6g:'CRQuestion6g',
   Question6h:'CRQuestion6h',
   Question6i:'CRQuestion6i',
   Question6jtxt:'CRQuestion6jtxt',
   Question6j:'CRQuestion6j',

   Question7:'CRQuestion7',
   Question8:'CRQuestion8',
   Question8a:'CRQuestion8a',
   Question9:'CRQuestion9',
   Question9a:'CRQuestion9a',
   Question10:'CRQuestion10',
   Question10a:'CRQuestion10a',
   Question11:'CRQuestion11',
   Question11a:'CRQuestion11a',
   Question12:'CRQuestion12',
   Question12a:'CRQuestion12a',
   Question13:'CRQuestion13',
   Question13a:'CRQuestion13a',

   Question14:'CRQuestion14',
   Question14a:'CRQuestion14a',
   Question14b:'CRQuestion14b',
   Question14c:'CRQuestion14c',
   Question14d:'CRQuestion14d',
   Question14etxt:'CRQuestion14etxt',
   Question14e:'CRQuestion14e',
   Question15:'CRQuestion15',
   Question15a:'CRQuestion15a',
   Question16:'CRQuestion16',
   Question16a:'CRQuestion16a',
   Question17:'CRQuestion17',
   Question17a:'CRQuestion17a',
   Question18:'CRQuestion18',
   Question18a:'CRQuestion18a',
   Question19:'CRQuestion19',
   Question19a:'CRQuestion19a',
   Question20:'CRQuestion20',
   Question20a:'CRQuestion20a',

   Question21:'CRQuestion21',
   Question22:'CRQuestion22',
   Question23:'CRQuestion23',
   Question24:'CRQuestion24',
   Question28:'CRQuestion28',

 /*   Question24:'CRQuestion24',
   Question25:'CRQuestion25',
   Question26:'CRQuestion26',
   Question27:'CRQuestion27',
   Question28:'CRQuestion28',
   Question28a:'CRQuestion28a',

   Question29:'CRQuestion29',
   Question30:'CRQuestion30',
   Question31:'CRQuestion31', */

}