import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  FormLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Radio,
  RadioGroup,
  Checkbox,
  FormControlLabel,
  TableCell,
  Table,
  TableRow
} from '@material-ui/core';
import useStyles from './styles';
import { SelectType } from '../common/DataTypes';
import SimplePopover from './SimplePopover';
import { FormMode } from '../forms/models/FormMode';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

function RowRadioButtonsGroup(props) {
  const classes = useStyles();
  const { label, data, selectType, description, mode, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  //const data2 =data &&  [""].concat(data).map(item=>{ return {label:item, value:item}});
  const data2 = data.map(item => { return { label: item, value: item } });
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText className='Mui-error'>{error}</FormHelperText>;
    }
    if (description) {
      return <SimplePopover {...props} content={description} />
    }
  }

  return (

    <>
      <TableCell>
        <FormLabel >{label}</FormLabel>
      </TableCell>
      <TableCell>
        <RadioGroup {...field} defaultValue={selectedValue} row>
          <Table>
            <TableRow>
              {data2.map((item, index) => (
                <TableCell style={{borderBottom:'none', textAlign:'center'}}> 
                  {mode == FormMode.Edit && <FormControlLabel control={<Radio checked={item.value === selectedValue} value={item.value} />} /> }
                  {mode == FormMode.Review && <FormControlLabel control={item.value === selectedValue? <CheckCircleIcon color='secondary' titleAccess={selectedValue}/> :<RadioButtonUncheckedIcon style={{color:'#ccc'}}/>} />}
                </TableCell>
              ))}
            </TableRow>
          </Table>
          {_renderHelperText()}
        </RadioGroup>
      </TableCell >
    </>
  );
}



RowRadioButtonsGroup.defaultProps = {
  data: [],
  mode:FormMode
};

RowRadioButtonsGroup.propTypes = {
  data: PropTypes.array.isRequired,
  mode:PropTypes.array.isRequired
};

export default RowRadioButtonsGroup;
