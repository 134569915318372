import React, { useContext, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from '../../forms/styles';
import { Section1, Section2, Section3, Section4, Section5, Section6 } from './';
import { FormMode } from '../models/FormMode';


export default function Review(props) {
    const classes = useStyles();
    const disclaimerText = props.init && props.init.formTexts.DisclaimerText;
    
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    return (
        <>
            <div className={classes.review}>
                <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>
                    {props.steps[props.stepId]}
                </Typography>
                <Grid container>
                    <Section1 {...props} mode={FormMode.Review} editable stepId={0} />
                    <Section2 {...props} mode={FormMode.Review} editable stepId={1} />
                     <Section3 {...props} mode={FormMode.Review} editable stepId={2} />
                    <Section4 {...props} mode={FormMode.Review} editable stepId={3} />
                    <div dangerouslySetInnerHTML={{ __html: disclaimerText }}></div>
                </Grid>
            </div>
        </>
    )
}