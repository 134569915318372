import MaterialLayout from './components/layout/MaterialLayout';
import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MainForm from './components/forms/MainForm';
import { UnknownCycle } from './components/fields';
import SecurityCheck from './components/forms/SecurityCheck';
import formInitialValues from './components/forms/models/formInitialValues';
import { ReportFields } from './components/common/DataTypes';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    loading: {
      textAlign: 'center',
    },
    notice: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff'
    },

  }),
);

function App() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = React.useState(0);
  const [init, setInit] = useState();
  const [formTexts, setFormTexts] = useState();
  const [config, setConfig] = useState();
  const [fieldsData, setFieldsData] = useState();
  const [initErrors, setInitErrors] = useState();
  const [cycles, setCycles] = useState();
  const [currentCycle, setCurrentCycle] = useState(null);
  const [stage, setStage] = useState(null);
  const [securityCheckResult, setSecurityCheckResult] = useState();
  const currentPath = window.location.pathname.replace('/', '');
  const currentCode = window.location.search.replace('?', '');

  useEffect(() => {
    getConfig()
  }, [])

  const handleSecurityCheck = (result) => {
    setStage(result);
  }

  const getStage = (config, cycle, code) => {
    console.log("Fetching:", config.ApiUrl);

    return new Promise((resolve, reject) => {
      const postData = {
        siteId: cycle.initData.siteId,
        listId: cycle.initData.listId,
        accessCode: code,
        docLibId: cycle.initData.docLibId
      };

      let apiUrl = `${config.ApiUrl}/stage`;

      return fetch(apiUrl,
        {
          body: JSON.stringify(postData),
          method: "POST",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${config.ApiToken}`
          }
        })
        .then(response => response.json())
        .then(json => {
          resolve(json.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  return (
    <div className={classes.root}>
      <MaterialLayout title={currentCycle && currentCycle.title || ''}>
        {loading && <CircularProgressWithLabel loading={classes.loading} value={progress} />}
        {!loading && currentCycle && !currentCycle.active && <UnknownCycle text={currentCycle.displayText} />}
        {/*  {!loading && currentCycle && currentCycle.active && stage && <MainForm stage={stage} config={config} init={currentCycle.initData} currentCycle={currentCycle} />} */}
        {!loading && currentCycle && currentCycle.active && stage && (stage.ReportStatus === 'Submitted') && <UnknownCycle text={currentCycle.initData.formTexts.ReportExpired || 'Expired'} />}
        {!loading && currentCycle && currentCycle.active && stage && (stage.ReportStatus === 'Draft' || stage.ReportStatus === 'Not Started') && <MainForm stage={stage} config={config} init={currentCycle.initData} currentCycle={currentCycle} />}
        {!loading && currentCycle && currentCycle.active && !stage && <SecurityCheck config={config} init={currentCycle.initData} currentCycle={currentCycle} handleSecurityCheck={handleSecurityCheck} />}
      </MaterialLayout>
    </div>

  );



  function getCycles(config) {
    console.log("Fetching:", config.ApiUrl);
    fetch(
      `${config.ApiUrl}/cycles`,
      {
        cache: "no-cache",
        headers: {
          "Authorization": `Bearer ${config.ApiToken}`
        }
      }
    ).then(response => {
      return response.json()
    }).then(result => {
      if (result && result.data) {
        setCycles(result.data);
        let current = result.data.find(cycle => cycle.cycle === 'CR');
        if (current) {
          setCurrentCycle(current);
          getStage(config, current, currentCode).then(stage => {
            if (stage) {
              const item = stage.Item;
              //Section 1
              formInitialValues.firstName = item[ReportFields.FirstName];
              formInitialValues.lastName = item[ReportFields.LastName];
              formInitialValues.email = item[ReportFields.Email];
              formInitialValues.confirmEmail = item[ReportFields.Email];
              formInitialValues.jobTitle = item[ReportFields.JobTitle];
              formInitialValues.orgName = item[ReportFields.OrgName];
              formInitialValues.charitysize = item[ReportFields.CharitySize];
              //Section 2
              formInitialValues.q6a = item[ReportFields.Question6a];
              formInitialValues.q6b = item[ReportFields.Question6b];
              formInitialValues.q6c = item[ReportFields.Question6c];
              formInitialValues.q6d = item[ReportFields.Question6d];
              formInitialValues.q6e = item[ReportFields.Question6e];
              formInitialValues.q6f = item[ReportFields.Question6f];
              formInitialValues.q6g = item[ReportFields.Question6g];
              formInitialValues.q6h = item[ReportFields.Question6h];
              formInitialValues.q6i = item[ReportFields.Question6i];
              // formInitialValues.q6j = item[ReportFields.Question6j];
              // formInitialValues.q6jtxt = item[ReportFields.Question6jtxt];
              formInitialValues.q6 = item[ReportFields.Question6];
              formInitialValues.q7 = item[ReportFields.Question7];
              formInitialValues.q8 = item[ReportFields.Question8];
              formInitialValues.q8a = item[ReportFields.Question8a];
              formInitialValues.q9 = item[ReportFields.Question9];
              //  formInitialValues.q9a = item[ReportFields.Question9a];
              formInitialValues.q10 = item[ReportFields.Question10];
              // formInitialValues.q10a = item[ReportFields.Question10a];
              formInitialValues.q11 = item[ReportFields.Question11];
              // formInitialValues.q11a = item[ReportFields.Question11a];
              formInitialValues.q12 = item[ReportFields.Question12];
              //  formInitialValues.q12a = item[ReportFields.Question12a];
              formInitialValues.q13 = item[ReportFields.Question13];
              // formInitialValues.q13a = item[ReportFields.Question13a];
              //Section 3
              formInitialValues.q28 = item[ReportFields.Question28] === 'Yes';
              formInitialValues.q14a = item[ReportFields.Question28] === 'Yes' ? item[ReportFields.Question14a] : '';
              formInitialValues.q14b = item[ReportFields.Question28] === 'Yes' ? item[ReportFields.Question14b] : '';
              formInitialValues.q14c = item[ReportFields.Question28] === 'Yes' ? item[ReportFields.Question14c] : '';
              formInitialValues.q14d = item[ReportFields.Question28] === 'Yes' ? item[ReportFields.Question14d] : '';
              formInitialValues.q14e = item[ReportFields.Question28] === 'Yes' ? item[ReportFields.Question14e] : '';

              // formInitialValues.q14etxt = item[ReportFields.Question14etxt];
              formInitialValues.q15 = item[ReportFields.Question28] === 'Yes' ? item[ReportFields.Question15] : '';
              // formInitialValues.q15a = item[ReportFields.Question15a];
              formInitialValues.q16 = item[ReportFields.Question28] === 'Yes' ? item[ReportFields.Question16] : '';
              //  formInitialValues.q16a = item[ReportFields.Question16a];
              formInitialValues.q17 = item[ReportFields.Question28] === 'Yes' ? item[ReportFields.Question17] : '';
              //  formInitialValues.q17a = item[ReportFields.Question17a];
              formInitialValues.q18 = item[ReportFields.Question28] === 'Yes' ? item[ReportFields.Question18] : '';
              //   formInitialValues.q18a = item[ReportFields.Question18a];
              formInitialValues.q19 = item[ReportFields.Question28] === 'Yes' ? item[ReportFields.Question19] : '';
              //  formInitialValues.q19a = item[ReportFields.Question19a];
              formInitialValues.q20 = item[ReportFields.Question28] === 'Yes' ? item[ReportFields.Question20] : '';

              //Section 4
              formInitialValues.q21 = item[ReportFields.Question21];
              formInitialValues.q22 = item[ReportFields.Question22];
              formInitialValues.q23 = item[ReportFields.Question23];
              formInitialValues.q24 = item[ReportFields.Question24];

              stage.formInitialValues = formInitialValues;
              stage.showIntro = true;
              setStage(stage);
              setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 50));
              setLoading(false);
            } else {
              setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 50));
              setLoading(false);
            }
          });
        } else {
          setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 50));
          setLoading(false);
        }
      } else {
        setInitErrors(result.errors);
        setLoading(false);
        console.log(result.errors);
      }
    })
  }

  function getConfig() {
    let apiUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api';
    console.log("Fetching config:", apiUrl);
    fetch(
      `${apiUrl}/config`,
      { cache: "no-cache" }
    ).then(response => {
      return response.json()
    }).then(result => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 50));
      if (result && result.data) {
        setConfig(result.data);
        setFormTexts(result.data.formTexts);
        getCycles(result.data);
      } else {
        setInitErrors(result.errors);
        //console.log(result.errors);
      }
    })
  }

  function CircularProgressWithLabel(props) {
    return (
      <div className={props.loading}><Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
      </div>
    );
  }
}

export default App;
