import React, { useEffect } from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { CheckboxField, InputAdornmentField, RowRadioButtonsGroup, InputRichTextField } from '../../fields';
import useStyles from '../styles';
import { useFormikContext } from 'formik';
import { FormMode } from '../models/FormMode';
import EditIcon from '@material-ui/icons/Edit';
import { ReportFields, TEXT_TOTAL_EXCEED } from '../../common/DataTypes';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function Section3(props) {
    const classes = useStyles();
    const { values: formValues } = useFormikContext();
    const title = <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary">{props.steps[props.stepId]}</Typography>;
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const {
        editable,
        formField: {
            q14a,
            q14b,
            q14c,
            q14d,
            q14e,
            q15,
            q16,
            q17,
            q18,
            q19,
            q20,
            q28,

        },
        getChoices,
        getField,
        mode
    } = props;

    const fieldQ14a = props.init && getField(ReportFields.Question14a);
    const fieldQ14b = props.init && getField(ReportFields.Question14b);
    const fieldQ14c = props.init && getField(ReportFields.Question14c);
    const fieldQ14d = props.init && getField(ReportFields.Question14d);
    const fieldQ14e = props.init && getField(ReportFields.Question14e);
    const fieldQ15 = props.init && getField(ReportFields.Question15);
    const q15Choices = getChoices(ReportFields.Question15);
    const fieldQ16 = props.init && getField(ReportFields.Question16);
    const q16Choices = getChoices(ReportFields.Question16);
    const fieldQ17 = props.init && getField(ReportFields.Question17);
    const q17Choices = getChoices(ReportFields.Question17);
    const fieldQ18 = props.init && getField(ReportFields.Question18);
    const q18Choices = getChoices(ReportFields.Question18);
    const fieldQ19 = props.init && getField(ReportFields.Question19);
    const q19Choices = getChoices(ReportFields.Question19);
    const fieldQ20 = props.init && getField(ReportFields.Question20);
    const fieldQ28 = props.init && getField(ReportFields.Question28);

    const q14aDesc = props.init && props.init.formTexts.FirstName;
    const q14bDesc = props.init && props.init.formTexts.FirstName;
    const q14cDesc = props.init && props.init.formTexts.FirstName;
    const q14dDesc = props.init && props.init.formTexts.FirstName;
    const q14eDesc = props.init && props.init.formTexts.FirstName;
    const q20Desc = props.init && props.init.formTexts.FirstName;
    const q28Desc = props.init && props.init.formTexts.Question28;


    const onEdit = (step) => {
        if (props.onEdit) {
            props.onEdit(step);
        }
    }

    const getTotal = () => {
        let total = 0;
        total = props.tryParse(formValues.q14a) + props.tryParse(formValues.q14b) + props.tryParse(formValues.q14c) + props.tryParse(formValues.q14d) + props.tryParse(formValues.q14e);
        props.validateForm(total > 100 || total < 100);
        return total;
    }

    const handleChange = (checked) => {
        if (checked) {
            props.validateForm(false);
        }
    }

    const edit = mode === FormMode.Review && editable && <IconButton aria-label="edit" onClick={() => onEdit(props.stepId)} className={classes.buttonEdit}><EditIcon /></IconButton>;

    return (
        <div className={classes.section1}>
            {(mode === FormMode.Review) &&
                <Grid container>
                    <Grid item xs={12}>
                        <List>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    {title}

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <Grid item xs={12} sm={12}>
                                            <ListItem button style={{ paddingLeft: 0 }}><ListItemText primary={fieldQ28.description} secondary={formValues.q28 ? "Yes" : "No"} /></ListItem>
                                        </Grid>
                                        {formValues.q28 && <Grid container spacing={3} style={{ marginTop: 0 }} >
                                            <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                                                <Typography className={classes.subTitle} color="secondary">{`Estimated allocation of volunteer time and pro-bono support`}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TableContainer component={Paper}>
                                                    <Table stickyHeader className={classes.table} aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Type of activity</TableCell>
                                                                <TableCell style={{ width: '30%' }}>Allocation of volunteering time and pro-bono support (%)</TableCell>
                                                            </TableRow>
                                                        </TableHead>

                                                        <TableBody>
                                                            <TableRow key={2}>
                                                                <TableCell component="th" scope="row">
                                                                    {`${fieldQ14a.description}`}
                                                                </TableCell>
                                                                <TableCell style={{ width: '30%' }}>
                                                                    {formValues.q14a}%
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow key={3}>
                                                                <TableCell component="th" scope="row">
                                                                    {`${fieldQ14b.description}`}
                                                                </TableCell>
                                                                <TableCell style={{ width: '30%' }}>
                                                                    {formValues.q14b}%
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow key={4}>
                                                                <TableCell component="th" scope="row">
                                                                    {`${fieldQ14c.description}`}
                                                                </TableCell>
                                                                <TableCell style={{ width: '30%' }}>
                                                                    {formValues.q14c}%
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow key={5}>
                                                                <TableCell component="th" scope="row">
                                                                    {`${fieldQ14d.description}`}
                                                                </TableCell>
                                                                <TableCell style={{ width: '30%' }}>
                                                                    {formValues.q14d}%
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow key={5}>
                                                                <TableCell component="th" scope="row">
                                                                    {`${fieldQ14e.description}`}
                                                                </TableCell>
                                                                <TableCell style={{ width: '30%' }}>
                                                                    {formValues.q14e}%
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell component="th" align="right"><strong>Total</strong></TableCell>
                                                                <TableCell align="left">
                                                                    <strong>{getTotal()}%</strong>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <Typography className={classes.subTitle} color="secondary">Impact of volunteering and pro-bono support</Typography>
                                                We want to understand how time given by Berkeley staff impact on your organisation in the following ways:
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <Grid item xs={12} sm={12}>
                                                    <TableContainer component={Paper}>
                                                        <Table stickyHeader className={classes.table} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell></TableCell>
                                                                    <TableCell>
                                                                        <Table>
                                                                            <TableRow>
                                                                                <TableCell style={{ borderBottom: 'none' }}>A lot</TableCell>
                                                                                <TableCell style={{ width: '80px', borderBottom: 'none' }}>A fair amount</TableCell>
                                                                                <TableCell style={{ borderBottom: 'none' }}>None</TableCell>
                                                                            </TableRow>
                                                                        </Table>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <RowRadioButtonsGroup
                                                                        name={q15.name}
                                                                        label={fieldQ15.description}
                                                                        data={q15Choices}
                                                                        mode={FormMode.Review}
                                                                    />
                                                                </TableRow>
                                                                <TableRow>
                                                                    <RowRadioButtonsGroup
                                                                        name={q16.name}
                                                                        label={fieldQ16.description}
                                                                        data={q16Choices}
                                                                        mode={FormMode.Review} />
                                                                </TableRow>
                                                                <TableRow>
                                                                    <RowRadioButtonsGroup
                                                                        name={q17.name}
                                                                        label={fieldQ17.description}
                                                                        data={q17Choices}
                                                                        mode={FormMode.Review} />
                                                                </TableRow>
                                                                <TableRow>
                                                                    <RowRadioButtonsGroup
                                                                        name={q18.name}
                                                                        label={fieldQ18.description}
                                                                        data={q18Choices}
                                                                        mode={FormMode.Review} />
                                                                </TableRow>
                                                                <TableRow>
                                                                    <RowRadioButtonsGroup
                                                                        name={q19.name}
                                                                        label={fieldQ19.description}
                                                                        data={q19Choices}
                                                                        mode={FormMode.Review} />
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <ListItem style={{ paddingLeft: 0 }} button><ListItemText primary={`${fieldQ20.description}`} secondary={''} />
                                                </ListItem>
                                                {formValues.q20 && formValues.q20.indexOf('<p><br></p>') === -1 && <div className={classes.reviewRichtext} style={{ marginTop: -30 }} dangerouslySetInnerHTML={{ __html: formValues.q20 }}></div>}
                                            </Grid>
                                        </Grid>}
                                        <Grid item xs={12} sm={12}>
                                            {edit}
                                        </Grid>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </List>
                    </Grid>
                </Grid>
            }

            {(mode === FormMode.Edit) &&
                <>
                    <Grid item xs={12} sm={12}>
                        {title}
                    </Grid>
                    <Grid>
                        <Grid item xs={12} sm={12}>
                            <CheckboxField name={q28.name} label={`${fieldQ28.description} *`} description={q28Desc} fullWidth variant="outlined" labelPlacement="start" onChange={handleChange} />
                        </Grid>
                    </Grid>
                    {formValues.q28 && <Grid container spacing={3} >
                        <Grid item xs={12} sm={12}>
                            <Typography className={classes.subTitle} color="secondary">{`Estimated allocation of volunteer time and pro-bono support`}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TableContainer component={Paper}>
                                <Table stickyHeader className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Type of activity</TableCell>
                                            <TableCell style={{ width: '30%' }}>Allocation of volunteering time and pro-bono support (%)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow key={2}>
                                            <TableCell component="th" scope="row">
                                                {`${fieldQ14a.description}`}
                                            </TableCell>
                                            <TableCell style={{ width: '30%' }}>
                                                <InputAdornmentField name={q14a.name} adornment="%" type="number" disabled={props.disabled} label={``} color="secondary" description={q14aDesc} placeholder='Enter value here' />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={3}>
                                            <TableCell component="th" scope="row">
                                                {`${fieldQ14b.description}`}
                                            </TableCell>
                                            <TableCell style={{ width: '30%' }}>
                                                <InputAdornmentField name={q14b.name} adornment="%" type="number" disabled={props.disabled} label={``} color="secondary" description={q14bDesc} placeholder='Enter value here' />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={4}>
                                            <TableCell component="th" scope="row">
                                                {`${fieldQ14c.description}`}
                                            </TableCell>
                                            <TableCell style={{ width: '30%' }}>
                                                <InputAdornmentField name={q14c.name} adornment="%" type="number" disabled={props.disabled} label={``} color="secondary" description={q14cDesc} placeholder='Enter value here' />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={5}>
                                            <TableCell component="th" scope="row">
                                                {`${fieldQ14d.description}`}
                                            </TableCell>
                                            <TableCell style={{ width: '30%' }}>
                                                <InputAdornmentField name={q14d.name} adornment="%" type="number" disabled={props.disabled} label={``} color="secondary" description={q14dDesc} placeholder='Enter value here' />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={5}>
                                            <TableCell component="th" scope="row">
                                                {`${fieldQ14e.description}`}
                                            </TableCell>
                                            <TableCell style={{ width: '30%' }}>
                                                <InputAdornmentField name={q14e.name} adornment="%" type="number" disabled={props.disabled} label={``} color="secondary" description={q14eDesc} placeholder='Enter value here' />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="right" style={{ verticalAlign: 'top' }}><strong>Total</strong></TableCell>
                                            <TableCell style={{ width: '20%', verticalAlign: 'top' }}>
                                                <strong>{getTotal()}%</strong>
                                                {(getTotal() > 100 || getTotal() < 100) ? <div style={{ color: '#f44336', fontSize: '0.75rem' }}>{TEXT_TOTAL_EXCEED}</div> : ''}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography className={classes.subTitle} color="secondary">Impact of volunteering and pro-bono support</Typography>
                            We want to understand how time given by Berkeley staff impact on your organisation in the following ways:
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TableContainer component={Paper}>
                                <Table stickyHeader className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>
                                                <Table>
                                                    <TableRow>
                                                        <TableCell style={{ borderBottom: 'none' }}>A lot</TableCell>
                                                        <TableCell style={{ width: '80px', borderBottom: 'none' }}>A fair amount</TableCell>
                                                        <TableCell style={{ borderBottom: 'none' }}>None</TableCell>
                                                    </TableRow>
                                                </Table>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <RowRadioButtonsGroup
                                                name={q15.name}
                                                label={fieldQ15.description}
                                                data={q15Choices}
                                                mode={FormMode.Edit}
                                            />
                                        </TableRow>
                                        <TableRow>
                                            <RowRadioButtonsGroup
                                                name={q16.name}
                                                label={fieldQ16.description}
                                                data={q16Choices}
                                                mode={FormMode.Edit} />
                                        </TableRow>
                                        <TableRow>
                                            <RowRadioButtonsGroup
                                                name={q17.name}
                                                label={fieldQ17.description}
                                                data={q17Choices}
                                                mode={FormMode.Edit} />
                                        </TableRow>
                                        <TableRow>
                                            <RowRadioButtonsGroup
                                                name={q18.name}
                                                label={fieldQ18.description}
                                                data={q18Choices}
                                                mode={FormMode.Edit} />
                                        </TableRow>
                                        <TableRow>
                                            <RowRadioButtonsGroup
                                                name={q19.name}
                                                label={fieldQ19.description}
                                                data={q19Choices}
                                                mode={FormMode.Edit} />
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <InputRichTextField
                                name={q20.name}
                                label={`${fieldQ20.description}`}
                                fullWidth
                                color="secondary" variant="outlined"
                                description={q20Desc}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                            <div style={{ float: 'right', fontWeight: 200, fontSize: '.9em', color: '#0000008a' }}><span>*</span> indicates required</div>
                        </Grid>
                    </Grid>}
                </>
            }
        </div >
    )
}