import React, { useEffect } from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { InputField, SelectField } from '../../fields';
import useStyles from '../styles';
import { useFormikContext } from 'formik';
import { FormMode } from '../models/FormMode';
import EditIcon from '@material-ui/icons/Edit';
import { ReportFields } from '../../common/DataTypes';
import { SelectType } from '../../common/DataTypes';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function Section1(props) {
    const classes = useStyles();
    const { values: formValues } = useFormikContext();
    const title = <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>{props.steps[props.stepId]}</Typography>;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const {
        editable,
        formField: {
            firstName,
            lastName,
            jobTitle,
            email,
            confirmEmail,
            orgName,
            charitysize
        },
        getChoices,
        getField,
        mode
    } = props;

    const fieldFirstName = props.init && getField(ReportFields.FirstName);
    const fieldLastName = props.init && getField(ReportFields.LastName);
    const fieldJobTitle = props.init && getField(ReportFields.JobTitle);
    const fieldEmail = props.init && getField(ReportFields.Email);
    const fieldOrganisationName = props.init && getField(ReportFields.OrgName);
    const fieldCharitySize = props.init && getField(ReportFields.CharitySize);
    const charitySizeChoices = getChoices(ReportFields.CharitySize);


    const emailDesc = props.init && props.init.formTexts.Email;
    const emailConfirmDesc = props.init && props.init.formTexts.EmailConfirm;
    const jobTitleDesc = props.init && props.init.formTexts.JobTitle;
    const lastNameDesc = props.init && props.init.formTexts.LastName;
    const firstNameDesc = props.init && props.init.formTexts.FirstName;
    const orgNameDesc = props.init && props.init.formTexts.OrgName;
    const charitySizeDesc = props.init && props.init.formTexts.CharitySize;

    const onEdit = (step) => {
        if (props.onEdit) {
            props.onEdit(step);
        }
    }

    const edit = mode === FormMode.Review && editable && <IconButton aria-label="edit" onClick={() => onEdit(props.stepId)} className={classes.buttonEdit}><EditIcon /></IconButton>;
    const securityCheckIntro = props.init && props.init.formTexts.IntroductionText;
    return (
        <div className={classes.section1}>
            {(mode === FormMode.Review) &&
                <Grid container>
                    <Grid item xs={12}>
                        <List>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    {title}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <ListItem button><ListItemText primary={fieldFirstName.description} secondary={formValues.firstName} /></ListItem>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <ListItem button><ListItemText primary={fieldLastName.description} secondary={formValues.lastName} /></ListItem>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <ListItem button><ListItemText primary={fieldJobTitle.description} secondary={formValues.jobTitle} /></ListItem>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <ListItem button><ListItemText primary={fieldEmail.description} secondary={formValues.email} /></ListItem>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <ListItem button><ListItemText primary={fieldOrganisationName.description} secondary={formValues.orgName} /></ListItem>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <ListItem button><ListItemText primary={fieldCharitySize.description} secondary={formValues.charitysize} /></ListItem>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                {edit}
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </List>
                    </Grid>
                </Grid>
            }

            {(mode === FormMode.Edit) &&
                <>
                    <Grid item xs={12}>
                        <div dangerouslySetInnerHTML={{ __html: securityCheckIntro }}></div>
                    </Grid>
                    <Grid item xs={12}>
                        {title}
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <InputField name={firstName.name} disabled={props.disabled} label={`${fieldFirstName.description} *`} fullWidth variant="outlined" color="secondary" description={firstNameDesc} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputField name={lastName.name} label={`${fieldLastName.description} *`} fullWidth variant="outlined" color="secondary" description={lastNameDesc} />
                        </Grid>

                        <Grid item xs={12}>
                            <InputField name={jobTitle.name} label={`${fieldJobTitle.description} *`} fullWidth variant="outlined" color="secondary" description={jobTitleDesc} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputField name={email.name} label={`${fieldEmail.description} *`} type="Email" fullWidth variant="outlined" color="secondary" description={emailDesc} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputField name={confirmEmail.name} label={`${fieldEmail.description} *`} type="Email" fullWidth variant="outlined" color="secondary" description={emailConfirmDesc} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputField name={orgName.name} label={`${fieldOrganisationName.description} `} InputProps={{ readOnly: true, }} fullWidth variant="outlined" color="secondary" description={orgNameDesc} />
                        </Grid>
                        <Grid item xs={12} className="selectRadio" sm={6}>
                            <SelectField
                                name={charitysize.name}
                                label={`${fieldCharitySize.description} *`}
                                data={charitySizeChoices}
                                fullWidth
                                color="secondary"
                                selectType={SelectType.RADIO}
                                description={charitySizeDesc}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                            <div style={{ float: 'right', fontWeight: 200, fontSize: '.9em', color: '#0000008a' }}><span>*</span> indicates required</div>
                        </Grid>
                    </Grid>
                </>
            }
        </div>
    )
}