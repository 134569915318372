import * as Yup from 'yup';
import formModel from './formModel';
const {
  formField: {
    firstName,
    lastName,
    jobTitle,
    email,
    confirmEmail,
    orgName,
    charitysize,
    q6jtxt,
    q6j,
    q8,
    q9,
    q10,
    q11,
    q12,
    q13,
    q14e,
    q15,
    q16,
    q17,
    q18,
    q19,
    q20,
    q21,
    q28,
  }
} = formModel;

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const postcodeRegExp = /^[a-zA-Z]{1,2}([0-9]{1,2}|[0-9][a-zA-Z])\s*[0-9][a-zA-Z]{2}$/;
const urlRegExp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export default [
  Yup.object().shape({
    [firstName.name]: Yup.string()
      .required(`${firstName.requiredErrorMsg}`)
      .max(255, `${firstName.label} First Name exceeded limited charcters!`),
    [lastName.name]: Yup.string()
      .max(255, `${lastName.label} Last Name exceeded limited charcters!`)
      .required(`${lastName.requiredErrorMsg}`),
    [jobTitle.name]: Yup.string()
      .required(`${jobTitle.requiredErrorMsg}`)
      .max(255, `${jobTitle.label} Job Title exceeded limited charcters!`),
    [email.name]: Yup.string()
      .required(`${email.requiredErrorMsg}`)
      .email(`Invalid Email Address`),
    [confirmEmail.name]: Yup.string()
      .required(`${confirmEmail.requiredErrorMsg}`)
      .email(`Invalid Email Address`)
      .when("email", {
        is: email => (email && email.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref("email")], `Email Address doesn't match!`)
      }),
    [charitysize.name]: Yup.string()
      .required(`${charitysize.requiredErrorMsg}`)

  }),
  Yup.object().shape({
    [q8.name]: Yup.string()
      .required(`${q8.requiredErrorMsg}`),
    [q9.name]: Yup.string()
      .required(`${q9.requiredErrorMsg}`),
    [q10.name]: Yup.string()
      .required(`${q10.requiredErrorMsg}`),
    [q11.name]: Yup.string()
      .required(`${q11.requiredErrorMsg}`),
    [q12.name]: Yup.string()
      .required(`${q12.requiredErrorMsg}`),
    [q13.name]: Yup.string()
      .required(`${q13.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [q15.name]: Yup.string()
      .when("q28", {
        is: true,
        then: Yup.string().required(`${q15.requiredErrorMsg}`)
      }),
    [q16.name]: Yup.string()
      .when("q28", {
        is: true,
        then: Yup.string().required(`${q16.requiredErrorMsg}`)
      }),
      [q17.name]: Yup.string()
      .when("q28", {
        is: true,
        then: Yup.string().required(`${q17.requiredErrorMsg}`)
      }),
      [q18.name]: Yup.string()
      .when("q28", {
        is: true,
        then: Yup.string().required(`${q18.requiredErrorMsg}`)
      }),
      [q19.name]: Yup.string()
      .when("q28", {
        is: true,
        then: Yup.string().required(`${q19.requiredErrorMsg}`)
      }),
    /*   [q15.name]: Yup.string()
        .required(`${q15.requiredErrorMsg}`),
      [q16.name]: Yup.string()
        .required(`${q16.requiredErrorMsg}`),
      [q17.name]: Yup.string()
        .required(`${q17.requiredErrorMsg}`),
      [q18.name]: Yup.string()
        .required(`${q18.requiredErrorMsg}`),
      [q19.name]: Yup.string()
        .required(`${q19.requiredErrorMsg}`) */
  }),
  Yup.object().shape({
    [q21.name]: Yup.string()
      .required(`${q21.requiredErrorMsg}`)
      .test('len', `${q21.requiredErrorMsg}`, val => {
        if (val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }
      ),
  }),
  Yup.object().shape({
    /* [q28a.name]: Yup.string()
      .when("q28", {
        is: true,
        then: () => Yup.string()
          .required(`This is required`)
          .test('len', `${q28a.requiredErrorMsg}`, val => {
            if (val && val.indexOf('<p><br></p>')) {
              return true;
            }
          }
          )
      }), */
  }),
  Yup.object().shape({}),
];
