import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
  Snackbar,
  IconButton ,Button ,CloseIcon
} from '@material-ui/core';
import useStyles from './styles';
import SimplePopover from './SimplePopover';


export default function CheckboxField(props) {
  const { label, description, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);


  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  function _renderDescriptionText() {
    if (description) {
      //return <p style={{color:'#0000008a', lineHeight:1.66, letterSpacing:0.033,padding:'4px 12px'}}>{description}</p>;
      return <SimplePopover {...props} content={description} positionTop={5} positionRight={10}/>;
    }
  }

  function _onChange(e) {
    setValue(e.target.checked);
    if(e.target.checked && props.hintYesText) {
      setOpen(true);
    }

    if(props.onChange) {
      props.onChange(e.target.checked);
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
     <FormControl {...rest} className={props.labelClass?classes.toggleQ5:classes.toggleText}>
      <FormControlLabel
        value={field.value}
        checked={field.value}
        control={<>{field.value?<span className='text selected'>Yes{props.showAstkYes?'*':''}</span>:<span className='text'>No{props.showAstkNo?'*':''}</span>}<Switch {...field} onChange={_onChange} checked={field.value}/></>}
        label={label}
        labelPlacement={props.labelPlacement}
      />
      {_renderDescriptionText()}
    </FormControl>

    <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={props.hintYesText}
        action={action}
        anchorOrigin={{  vertical: 'bottom', horizontal: 'left' }}
      />
    </>
  );
}
