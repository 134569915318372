import React, { useEffect } from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { InputAdornmentField, InputRichTextField, RowRadioButtonsGroup } from '../../fields';
import useStyles from '../styles';
import { useFormikContext } from 'formik';
import { FormMode } from '../models/FormMode';
import EditIcon from '@material-ui/icons/Edit';
import { ReportFields, TEXT_TOTAL_EXCEED } from '../../common/DataTypes';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


export default function Section2(props) {
    const classes = useStyles();
    const { values: formValues } = useFormikContext();
    const title = <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>{props.steps[props.stepId]}</Typography>;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const {
        editable,
        formField: {
            q6a,
            q6b,
            q6c,
            q6d,
            q6e,
            q6f,
            q6g,
            q6h,
            q6i,
            q7,
            q8,
            q8a,
            q9,
            q10,
            q11,
            q12,
            q13,

        },
        getChoices,
        getField,
        mode
    } = props;

    const fieldQ6 = props.init && getField(ReportFields.Question6);
    const fieldQ6a = props.init && getField(ReportFields.Question6a);
    const fieldQ6b = props.init && getField(ReportFields.Question6b);
    const fieldQ6c = props.init && getField(ReportFields.Question6c);
    const fieldQ6d = props.init && getField(ReportFields.Question6d);
    const fieldQ6e = props.init && getField(ReportFields.Question6e);
    const fieldQ6f = props.init && getField(ReportFields.Question6f);
    const fieldQ6g = props.init && getField(ReportFields.Question6g);
    const fieldQ6h = props.init && getField(ReportFields.Question6h);
    const fieldQ6i = props.init && getField(ReportFields.Question6i);
    const fieldQ7 = props.init && getField(ReportFields.Question7);
    const fieldQ8 = props.init && getField(ReportFields.Question8);
    const q8Choices = getChoices(ReportFields.Question8);
    const fieldQ8a = props.init && getField(ReportFields.Question8a);
    const fieldQ9 = props.init && getField(ReportFields.Question9);
    const q9Choices = getChoices(ReportFields.Question9);
    const fieldQ10 = props.init && getField(ReportFields.Question10);
    const q10Choices = getChoices(ReportFields.Question10);
    const fieldQ11 = props.init && getField(ReportFields.Question11);
    const q11Choices = getChoices(ReportFields.Question11);
    const fieldQ12 = props.init && getField(ReportFields.Question12);
    const q12Choices = getChoices(ReportFields.Question12);
    const fieldQ13 = props.init && getField(ReportFields.Question13);
    const q13Choices = getChoices(ReportFields.Question13);


    const q6aDesc = props.init && props.init.formTexts.FirstName;
    const q6bDesc = props.init && props.init.formTexts.FirstName;
    const q6cDesc = props.init && props.init.formTexts.FirstName;
    const q6dDesc = props.init && props.init.formTexts.FirstName;
    const q6eDesc = props.init && props.init.formTexts.FirstName;
    const q6fDesc = props.init && props.init.formTexts.FirstName;
    const q6gDesc = props.init && props.init.formTexts.FirstName;
    const q6hDesc = props.init && props.init.formTexts.FirstName;
    const q6iDesc = props.init && props.init.formTexts.FirstName;
    const q7Desc = props.init && props.init.formTexts.FirstName;
    const q8Desc = props.init && props.init.formTexts.FirstName;


    const onEdit = (step) => {
        if (props.onEdit) {
            props.onEdit(step);
        }
    }



    const getTotal = () => {
        let total = 0;
        total = props.tryParse(formValues.q6a) + props.tryParse(formValues.q6b) + props.tryParse(formValues.q6c) + props.tryParse(formValues.q6d) + props.tryParse(formValues.q6e) + props.tryParse(formValues.q6f) + props.tryParse(formValues.q6g) + props.tryParse(formValues.q6h) + props.tryParse(formValues.q6i);
        props.validateForm(total > 100 || total < 100);
        return total;
    }

    const edit = mode === FormMode.Review && editable && <IconButton aria-label="edit" onClick={() => onEdit(props.stepId)} className={classes.buttonEdit}><EditIcon /></IconButton>;

    return (
        <div className={classes.section1}>
            {(mode === FormMode.Review) &&
                <Grid container>
                    <Grid item xs={12}>
                        <List>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    {title}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography className={classes.subTitle} color="secondary">Estimated allocation of funding</Typography>
                                                    <span style={{ color: '#0000008a' }}>{`${fieldQ6.description}`}</span>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TableContainer component={Paper}>
                                                        <Table stickyHeader className={classes.table} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Organisation budget line</TableCell>
                                                                    <TableCell style={{ width: '30%' }}>Estimated allocation (%)</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow key={2}>
                                                                    <TableCell component="th" scope="row">
                                                                        {`${fieldQ6a.description}`}
                                                                    </TableCell>
                                                                    <TableCell style={{ width: '30%' }}>
                                                                        {formValues.q6a}%
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow key={3}>
                                                                    <TableCell component="th" scope="row">
                                                                        {`${fieldQ6b.description}`}
                                                                    </TableCell>
                                                                    <TableCell style={{ width: '30%' }}>
                                                                        {formValues.q6b}%
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow key={4}>
                                                                    <TableCell component="th" scope="row">
                                                                        {`${fieldQ6c.description}`}
                                                                    </TableCell>
                                                                    <TableCell style={{ width: '30%' }}>
                                                                        {formValues.q6c}%
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow key={5}>
                                                                    <TableCell component="th" scope="row">
                                                                        {`${fieldQ6d.description}`}
                                                                    </TableCell>
                                                                    <TableCell style={{ width: '30%' }}>
                                                                        {formValues.q6d}%
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow key={6}>
                                                                    <TableCell component="th" scope="row">
                                                                        {`${fieldQ6e.description}`}
                                                                    </TableCell>
                                                                    <TableCell style={{ width: '30%' }}>
                                                                        {formValues.q6e}%
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow key={7}>
                                                                    <TableCell component="th" scope="row">
                                                                        {`${fieldQ6f.description}`}
                                                                    </TableCell>
                                                                    <TableCell style={{ width: '30%' }}>
                                                                        {formValues.q6f}%
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow key={7}>
                                                                    <TableCell component="th" scope="row">
                                                                        {`${fieldQ6g.description}`}
                                                                    </TableCell>
                                                                    <TableCell style={{ width: '30%' }}>
                                                                        {formValues.q6g}%
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow key={7}>
                                                                    <TableCell component="th" scope="row">
                                                                        {`${fieldQ6h.description}`}
                                                                    </TableCell>
                                                                    <TableCell style={{ width: '30%' }}>
                                                                        {formValues.q6h}%
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow key={8}>
                                                                    <TableCell component="th" scope="row">
                                                                        {`${fieldQ6i.description}`}
                                                                    </TableCell>
                                                                    <TableCell style={{ width: '30%' }}>
                                                                        {formValues.q6i}%
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell component="th" align="right"><strong>Total</strong></TableCell>
                                                                    <TableCell align="left">
                                                                        <strong>{getTotal()}%</strong>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <ListItem style={{ paddingLeft: 0 }} button><ListItemText primary={`${fieldQ7.description}`} />
                                                    </ListItem>
                                                    <div style={{ marginTop: -24 }} className={classes.reviewRichtext} dangerouslySetInnerHTML={{ __html: formValues.q7 }}></div>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3} style={{ marginTop: 8 }} >
                                                <Grid item xs={12} sm={12}>
                                                    <Typography className={classes.subTitle} color="secondary">Impact of funding</Typography>
                                                    <span style={{ color: '#0000008a' }}>We would like to understand how funds raised and donated by Berkeley staff and grants given by the Foundation have impacted your organisation over the last year. Please indicate the degree to which our funding has impacted your organisation in the following areas:
                                                    </span>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TableContainer component={Paper}>
                                                        <Table stickyHeader className={classes.table} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell></TableCell>
                                                                    <TableCell>
                                                                        <Table>
                                                                            <TableRow>
                                                                                <TableCell style={{ borderBottom: 'none' }}>A lot</TableCell>
                                                                                <TableCell style={{ width: '80px', borderBottom: 'none' }}>A fair amount</TableCell>
                                                                                <TableCell style={{ borderBottom: 'none' }}>None</TableCell>
                                                                            </TableRow>
                                                                        </Table>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <RowRadioButtonsGroup
                                                                        name={q8.name}
                                                                        label={fieldQ8.description}
                                                                        data={q8Choices}
                                                                        mode={FormMode.Review}
                                                                    />
                                                                </TableRow>
                                                                <TableRow>
                                                                    <RowRadioButtonsGroup
                                                                        name={q9.name}
                                                                        label={fieldQ9.description}
                                                                        data={q9Choices}
                                                                        mode={FormMode.Review}
                                                                    />
                                                                </TableRow>
                                                                <TableRow>
                                                                    <RowRadioButtonsGroup
                                                                        name={q10.name}
                                                                        label={fieldQ10.description}
                                                                        data={q10Choices}
                                                                        mode={FormMode.Review}
                                                                    />
                                                                </TableRow>
                                                                <TableRow>
                                                                    <RowRadioButtonsGroup
                                                                        name={q11.name}
                                                                        label={fieldQ11.description}
                                                                        data={q11Choices}
                                                                        mode={FormMode.Review}
                                                                    />
                                                                </TableRow>
                                                                <TableRow>
                                                                    <RowRadioButtonsGroup
                                                                        name={q12.name}
                                                                        label={fieldQ12.description}
                                                                        data={q12Choices}
                                                                        mode={FormMode.Review}
                                                                    />
                                                                </TableRow>
                                                                <TableRow>
                                                                    <RowRadioButtonsGroup
                                                                        name={q13.name}
                                                                        label={fieldQ13.description}
                                                                        data={q13Choices}
                                                                        mode={FormMode.Review}
                                                                    />
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <ListItem style={{ paddingLeft: 0 }} button><ListItemText primary={`${fieldQ8a.description}`} />
                                                    </ListItem>
                                                    <div style={{ marginTop: -30 }} className={classes.reviewRichtext} dangerouslySetInnerHTML={{ __html: formValues.q8a }}></div>
                                                    {edit}
                                                </Grid>
                                            </Grid>
                                        </>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </List>
                    </Grid>
                </Grid>
            }

            {(mode === FormMode.Edit) &&
                <>
                    <Grid item xs={12}>
                        {title}
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <Typography className={classes.subTitle} color="secondary">Estimated allocation of funding</Typography>
                            <span style={{ color: '#0000008a' }}>{`${fieldQ6.description}`}</span>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                        <TableContainer component={Paper}>
                                <Table stickyHeader className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Organisation budget line</TableCell>
                                            <TableCell style={{ width: '30%' }}>Estimated allocation (%)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow key={2}>
                                            <TableCell component="th" scope="row">
                                                {`${fieldQ6a.description}`}
                                            </TableCell>
                                            <TableCell style={{ width: '30%' }}>
                                                <InputAdornmentField name={q6a.name} adornment="%" type="number" disabled={props.disabled} label={``} color="secondary" description={q6aDesc} placeholder='Enter value here' />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={3}>
                                            <TableCell component="th" scope="row">
                                                {`${fieldQ6b.description}`}
                                            </TableCell>
                                            <TableCell style={{ width: '30%' }}>
                                                <InputAdornmentField name={q6b.name} adornment="%" type="number" disabled={props.disabled} label={``} color="secondary" description={q6bDesc} placeholder='Enter value here' />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={4}>
                                            <TableCell component="th" scope="row">
                                                {`${fieldQ6c.description}`}
                                            </TableCell>
                                            <TableCell style={{ width: '30%' }}>
                                                <InputAdornmentField name={q6c.name} adornment="%" type="number" disabled={props.disabled} label={``} color="secondary" description={q6cDesc} placeholder='Enter value here' />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={5}>
                                            <TableCell component="th" scope="row">
                                                {`${fieldQ6d.description}`}
                                            </TableCell>
                                            <TableCell style={{ width: '30%' }}>
                                                <InputAdornmentField name={q6d.name} adornment="%" type="number" disabled={props.disabled} label={``} color="secondary" description={q6dDesc} placeholder='Enter value here' />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={6}>
                                            <TableCell component="th" scope="row">
                                                {`${fieldQ6e.description}`}
                                            </TableCell>
                                            <TableCell style={{ width: '30%' }}>
                                                <InputAdornmentField name={q6e.name} adornment="%" type="number" disabled={props.disabled} label={``} color="secondary" description={q6eDesc} placeholder='Enter value here' />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={7}>
                                            <TableCell component="th" scope="row">
                                                {`${fieldQ6f.description}`}
                                            </TableCell>
                                            <TableCell style={{ width: '30%' }}>
                                                <InputAdornmentField name={q6f.name} adornment="%" type="number" disabled={props.disabled} label={``} color="secondary" description={q6fDesc} placeholder='Enter value here' />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={7}>
                                            <TableCell component="th" scope="row">
                                                {`${fieldQ6g.description}`}
                                            </TableCell>
                                            <TableCell style={{ width: '30%' }}>
                                                <InputAdornmentField name={q6g.name} adornment="%" type="number" disabled={props.disabled} label={``} color="secondary" description={q6gDesc} placeholder='Enter value here' />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={7}>
                                            <TableCell component="th" scope="row">
                                                {`${fieldQ6h.description}`}
                                            </TableCell>
                                            <TableCell style={{ width: '30%' }}>
                                                <InputAdornmentField name={q6h.name} adornment="%" type="number" disabled={props.disabled} label={``} color="secondary" description={q6hDesc} placeholder='Enter value here' />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={8}>
                                            <TableCell component="th" scope="row">
                                                {`${fieldQ6i.description}`}
                                            </TableCell>
                                            <TableCell style={{ width: '30%' }}>
                                                <InputAdornmentField name={q6i.name} adornment="%" type="number" disabled={props.disabled} label={``} color="secondary" description={q6iDesc} placeholder='Enter value here' />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="right" style={{ verticalAlign: 'top' }}><strong>Total</strong></TableCell>
                                            <TableCell style={{ width: '20%', verticalAlign: 'top' }}>
                                                <strong>{getTotal()}%</strong>
                                                {(getTotal() > 100 || getTotal() < 100) ? <div style={{ color: '#f44336', fontSize: '0.75rem' }}>{TEXT_TOTAL_EXCEED}</div> : ''}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <InputRichTextField
                                name={q7.name}
                                label={`${fieldQ7.description}`}
                                fullWidth
                                color="secondary" variant="outlined"
                                description={q7Desc}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Typography className={classes.subTitle} color="secondary">Impact of funding</Typography>
                            <span style={{ color: '#0000008a' }}>We would like to understand how funds raised and donated by Berkeley staff and grants given by the Foundation have impacted your organisation over the last year. Please indicate the degree to which our funding has impacted your organisation in the following areas:</span>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TableContainer component={Paper}>
                                <Table stickyHeader className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>
                                                <Table>
                                                    <TableRow>
                                                        <TableCell style={{ borderBottom: 'none' }}>A lot</TableCell>
                                                        <TableCell style={{ width: '80px', borderBottom: 'none' }}>A fair amount</TableCell>
                                                        <TableCell style={{ borderBottom: 'none' }}>None</TableCell>
                                                    </TableRow>
                                                </Table>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <RowRadioButtonsGroup
                                                name={q8.name}
                                                label={fieldQ8.description}
                                                data={q8Choices}
                                                mode={FormMode.Edit}
                                            />
                                        </TableRow>
                                        <TableRow>
                                            <RowRadioButtonsGroup
                                                name={q9.name}
                                                label={fieldQ9.description}
                                                data={q9Choices}
                                                mode={FormMode.Edit} />
                                        </TableRow>
                                        <TableRow>
                                            <RowRadioButtonsGroup
                                                name={q10.name}
                                                label={fieldQ10.description}
                                                data={q10Choices}
                                                mode={FormMode.Edit} />
                                        </TableRow>
                                        <TableRow>
                                            <RowRadioButtonsGroup
                                                name={q11.name}
                                                label={fieldQ11.description}
                                                data={q11Choices}
                                                mode={FormMode.Edit} />
                                        </TableRow>
                                        <TableRow>
                                            <RowRadioButtonsGroup
                                                name={q12.name}
                                                label={fieldQ12.description}
                                                data={q12Choices}
                                                mode={FormMode.Edit} />
                                        </TableRow>
                                        <TableRow>
                                            <RowRadioButtonsGroup
                                                name={q13.name}
                                                label={fieldQ13.description}
                                                data={q13Choices}
                                                mode={FormMode.Edit} />
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <InputRichTextField
                                name={q8a.name}
                                label={`${fieldQ8a.description}`}
                                fullWidth
                                color="secondary" variant="outlined"
                                description={q8Desc}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                            <div style={{ float: 'right', fontWeight: 200, fontSize: '.9em', color: '#0000008a' }}><span>*</span> indicates required</div>
                        </Grid>
                    </Grid>
                </>
            }
        </div>
    )
}