import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Radio,
  Checkbox
} from '@material-ui/core';
import useStyles from './styles';
import {SelectType} from '../common/DataTypes';
import SimplePopover from './SimplePopover';


function SelectField(props) {
  const classes = useStyles();
  const { label, data, selectType,description, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  //const data2 =data &&  [""].concat(data).map(item=>{ return {label:item, value:item}});
  const data2 =data.map(item=>{ return {label:item, value:item}});
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
    if (description) {
      return <SimplePopover {...props} content={description}/>
      }}

  return (
    <FormControl {...rest} error={isError} variant="outlined"  className={props.withText ? classes.singleSelectWithText : classes.singleSelect}>
      <InputLabel>{label}</InputLabel>
       <Select {...field} value={selectedValue ? selectedValue : ''} autoComplete="off">
        {data2.map((item, index) => (
          <MenuItem key={index} value={item.value} style={{fontSize:14}}>
              {item.label && selectType == SelectType.RADIO && <Radio checked={item.value == selectedValue}/>}
              {item.label && selectType == SelectType.CHECK && <Checkbox checked={item.value == selectedValue}/>}
              {item.label}
          </MenuItem>
        ))}
      </Select> 
      
      {_renderHelperText()}
    </FormControl>
  );
}



SelectField.defaultProps = {
  data: []
};

SelectField.propTypes = {
  data: PropTypes.array.isRequired
};

export default SelectField;
