import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(960 + theme.spacing(2) * 2)]: {
      width: 960,
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    '& .MuiToolbar-root': {
      [theme.breakpoints.up(960 + theme.spacing(2) * 2)]: {
        width: 960,
        marginLeft: 'auto',
        marginRight: 'auto'
      },
    }
  },
  appBar:{
    position: 'fixed',
    backgroundColor:'#fafafa',
    color:'#666',

  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  header: {
    flexGrow: 1,
    fontFamily:'Rockwell-MT',
    marginLeft: theme.spacing(4),
    fontSize: '1.8em',
   },
  overlayContainer: {
    backgroundColor:theme.palette.primary.main,
    height: 72,
    opacity:0.8
  }
}));
