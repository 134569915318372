import securityCheckFormModel from './securityCheckFormModel';
const {
  formField: {
    withCode,
    code
  }
} = securityCheckFormModel;
const DEVTEXT = 'development1';

 export default {
  [withCode.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?false:false,
  [code.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT? 'b24sg':'',
}; 
