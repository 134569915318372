export default {
  formId: 'mainForm',
  formField: {
    //Section 1 - Organisation details
    firstName: {
      name: 'firstName',
      label: '1.',
      requiredErrorMsg: 'This field is required'
    },
    lastName: {
      name: 'lastName',
      label: '',
      requiredErrorMsg: 'This field is required'
    },

    jobTitle: {
      name: 'jobTitle',
      label: '2.',
      requiredErrorMsg: 'This field is required'
    },
    email: {
      name: 'email',
      label: '3.',
      requiredErrorMsg: 'This field is required'
    },
    confirmEmail: {
      name: 'confirmEmail',
      label: 'Confirm',
      requiredErrorMsg: 'This field is required'
    },
    orgName: {
      name: 'orgName',
      label: '4.',
      requiredErrorMsg: 'This field is required'
    },
    charitysize: {
      name: 'charitysize',
      label: '5.',
      requiredErrorMsg: 'This field is required'
    },

    //Section 2 - Unrestricted funding
    q6: {
      name: 'q6',
      label: '6.',
      requiredErrorMsg: 'This field is required'
    },
    q6a: {
      name: 'q6a',
      label: '6.',
      requiredErrorMsg: 'This field is required'
    },
    q6b: {
      name: 'q6b',
      label: '6.',
      requiredErrorMsg: 'This field is required'
    },
    q6c: {
      name: 'q6c',
      label: '6.',
      requiredErrorMsg: 'This field is required'
    },
    q6d: {
      name: 'q6d',
      label: '6.',
      requiredErrorMsg: 'This field is required'
    },
    q6e: {
      name: 'q6e',
      label: '6.',
      requiredErrorMsg: 'This field is required'
    },
    q6f: {
      name: 'q6f',
      label: '6.',
      requiredErrorMsg: 'This field is required'
    },
    q6g: {
      name: 'q6g',
      label: '6.',
      requiredErrorMsg: 'This field is required'
    },
    q6h: {
      name: 'q6h',
      label: '6.',
      requiredErrorMsg: 'This field is required'
    },
    q6i: {
      name: 'q6i',
      label: '6.',
      requiredErrorMsg: 'This field is required'
    },
    q6j: {
      name: 'q6j',
      label: '6.',
      requiredErrorMsg: 'This field is required'
    },
    q6jtxt: {
      name: 'q6jtxt',
      label: '6.',
      requiredErrorMsg: 'This field is required'
    },

    q7: {
      name: 'q7',
      label: '7.',
      requiredErrorMsg: 'This field is required'
    },
    q8: {
      name: 'q8',
      label: '8.',
      requiredErrorMsg: 'This field is required'
    },
    q8a: {
      name: 'q8a',
      label: '8.',
      requiredErrorMsg: 'This field is required'
    },
    q9: {
      name: 'q9',
      label: '9.',
      requiredErrorMsg: 'This field is required'
    },
    q9a: {
      name: 'q9a',
      label: '9.',
      requiredErrorMsg: 'This field is required'
    },
    q10: {
      name: 'q10',
      label: '10.',
      requiredErrorMsg: 'This field is required'
    },
    q10a: {
      name: 'q10a',
      label: '15.',
      requiredErrorMsg: 'This field is required'
    },
    q11: {
      name: 'q11',
      label: '11.',
      requiredErrorMsg: 'This field is required'
    },
    q11a: {
      name: 'q11a',
      label: '11.',
      requiredErrorMsg: 'This field is required'
    },
    q12: {
      name: 'q12',
      label: '12.',
      requiredErrorMsg: 'This field is required'
    },
    q12a: {
      name: 'q12a',
      label: '12.',
      requiredErrorMsg: 'This field is required'
    },
    q13: {
      name: 'q13',
      label: '13.',
      requiredErrorMsg: 'This field is required'
    },
    q13a: {
      name: 'q13a',
      label: '13.',
      requiredErrorMsg: 'This field is required'
    },

    //Section 3 - Volunteering and pro-bono support
    q14: {
      name: 'q14',
      label: '14.',
      requiredErrorMsg: 'This field is required'
    },
    q14a: {
      name: 'q14a',
      label: '14.',
      requiredErrorMsg: 'This field is required'
    },
    q14b: {
      name: 'q14b',
      label: '14.',
      requiredErrorMsg: 'This field is required'
    },
    q14c: {
      name: 'q14c',
      label: '14.',
      requiredErrorMsg: 'This field is required'
    },
    q14d: {
      name: 'q14d',
      label: '14.',
      requiredErrorMsg: 'This field is required'
    },
    q14etxt: {
      name: 'q14etxt',
      label: '14.',
      requiredErrorMsg: 'This field is required'
    },
    q14e: {
      name: 'q14e',
      label: '14.',
      requiredErrorMsg: 'This field is required'
    },

    q15: {
      name: 'q15',
      label: '15.',
      requiredErrorMsg: 'This field is required'
    },
    q15a: {
      name: 'q15a',
      label: '15.',
      requiredErrorMsg: 'This field is required'
    },
    q16: {
      name: 'q16',
      label: '16.',
      requiredErrorMsg: 'This field is required'
    },
    q16a: {
      name: 'q16a',
      label: '16.',
      requiredErrorMsg: 'This field is required'
    },
    q17: {
      name: 'q17',
      label: '17.',
      requiredErrorMsg: 'This field is required'
    },
    q17a: {
      name: 'q17a',
      label: '17.',
      requiredErrorMsg: 'This field is required'
    },
    q18: {
      name: 'q18',
      label: '18.',
      requiredErrorMsg: 'This field is required'
    },
    q18a: {
      name: 'q18a',
      label: '18.',
      requiredErrorMsg: 'This field is required'
    },
    q19: {
      name: 'q19',
      label: '19.',
      requiredErrorMsg: 'This field is required'
    },
    q19a: {
      name: 'q19a',
      label: '19.',
      requiredErrorMsg: 'This field is required'
    },
    q20: {
      name: 'q20',
      label: '20.',
      requiredErrorMsg: 'This field is required'
    },
    q20a: {
      name: 'q20a',
      label: '20.',
      requiredErrorMsg: 'This field is required'
    },
    //Section 4 - Organisation achievements and plans
    q21: {
      name: 'q21',
      label: '21.',
      requiredErrorMsg: 'This field is required'
    },
    q22: {
      name: 'q22',
      label: '22.',
      requiredErrorMsg: 'This field is required'
    },
    q23: {
      name: 'q23',
      label: '23.',
      requiredErrorMsg: 'This field is required'
    },
    //Section 5 - Equity, Diversity & Inclusion
    q24: {
      name: 'q24',
      label: '24.',
      requiredErrorMsg: 'This field is required'
    },
    q25: {
      name: 'q25',
      label: '25.',
      requiredErrorMsg: 'This field is required'
    },
    q26: {
      name: 'q26',
      label: '26.',
      requiredErrorMsg: 'This field is required'
    },
    q27: {
      name: 'q27',
      label: '27.',
      requiredErrorMsg: 'This field is required'
    },
    q28: {
      name: 'q28',
      label: '28.',
      requiredErrorMsg: 'This field is required'
    },
    q28a: {
      name: 'q28a',
      label: '28.',
      requiredErrorMsg: 'This field is required'
    },
    //Section 6 - Feedback
    q29: {
      name: 'q29',
      label: '29.',
      requiredErrorMsg: 'This field is required'
    },
    q30: {
      name: 'q30',
      label: '30.',
      requiredErrorMsg: 'This field is required'
    },
    q31: {
      name: 'q31',
      label: '31.',
      requiredErrorMsg: 'This field is required'
    },
    attachments: {
      name: 'attachments',
      label: 'Supporting Documents',
      requiredErrorMsg: 'This field is required'
    },
  }
};
